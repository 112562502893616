import { createTheme } from "@mui/material/styles"

export default function createMyTheme() {
  // 1) create your normal theme object
  let theme = createTheme({
    palette: {
      primary: {
        main: "#oc2617", // Customize your primary color
      },
      secondary: {
        main: "#5A6d62", // Customize your secondary color
      },
    },
    typography: {
      fontFamily: '"Exo 2", serif !important', // Customize your typography
    },
    // Add more customizations here if needed
  })

  // 2) Provide a custom ssrMatchMedia so server & client match
  theme = {
    ...theme,
    components: {
      ...theme.components,
      MuiUseMediaQuery: {
        defaultProps: {
          // This function simulates matchMedia on the server
          // We can forcibly return `matches: false` if we want
          // to pretend we are always in a wide screen,
          // or do a more advanced check. This is an example:
          ssrMatchMedia: query => ({
            // For the simplest approach, always treat screen as wide
            matches: query.includes("(max-width:"),
          }),
        },
      },
    },
  }

  return theme
}
